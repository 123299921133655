import React from 'react';
import styled from 'styled-components';
import { useIntl } from '@utils/localize';

import colors from '@utils/colors';
import EventSummary from '@components/EventSummary';
import { Heading as UnstyledHeading } from '@components/type';

const Heading = styled(UnstyledHeading)`
  margin-bottom: 24px;
  padding-bottom: 32px;
  border-bottom: 3px solid ${colors.B100};
`;

const Wrapper = styled.div`

`;

function UpcomingEvents({ events }) {
  const intl = useIntl();

  return (
    <Wrapper>
      <Heading level={200}>{intl.formatMessage({ id: 'otherUpcomingEvents' })}</Heading>
      {events.map((event, idx) => (
        <EventSummary key={idx} {...event} />
      ))}
    </Wrapper>
  );
}

export default UpcomingEvents;
